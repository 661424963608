import { FocalPointDialogOpener, focalPointPoints, focalPointPreviews } from '@app/components/focal-point'
import { Component, DisconnectEntityTrigger, EntityView, Field, useEntity } from '@contember/interface'
import { FormErrorContext, FormFieldIdContext } from '@contember/react-form'
import {
	AnyFileTypeProps,
	AudioFileTypeProps,
	createAnyFileType,
	createAudioFileType,
	createImageFileType,
	createVideoFileType,
	FileType,
	FileUrlDataExtractorProps,
	ImageFileTypeProps,
	Uploader,
	UploaderBase,
	UploaderBaseFieldProps,
	UploaderHasFile,
	useS3Client,
	VideoFileTypeProps,
} from '@contember/react-uploader'
import * as React from 'react'
import { ReactNode, useId, useMemo, useState } from 'react'
import {
	UploadedAnyView,
	UploadedAudioView,
	UploadedImageView,
	UploadedVideoView,
	UploaderDropzone,
	UploaderItemUI,
} from '../upload'
import { UploaderProgress } from '../upload/upload-progress'
import { FormContainer, FormContainerProps } from './container'

export type BaseUploadFieldProps = Omit<FormContainerProps, 'children'> &
	UploaderBaseFieldProps & {
		dropzonePlaceholder?: ReactNode
		actions?: ReactNode
		edit?: ReactNode
		noDestroy?: boolean
	}

export type ImageFieldProps = BaseUploadFieldProps & ImageFileTypeProps

export const ImageField = Component<ImageFieldProps>(props => (
	<UploadFieldInner {...props} fileType={createImageFileType(props)}>
		{props.children ?? (
			<EntityView
				field={(props.baseField as string) ?? undefined}
				render={accessor => (
					<FocalPointDialogOpener
						entity={`Image(id = '${accessor.id}')`}
						urlField="url"
						widthField="width"
						heightField="height"
						previews={focalPointPreviews}
						points={focalPointPoints}
					>
						<UploadedImageView {...props} DestroyAction={DisconnectEntityTrigger} />
					</FocalPointDialogOpener>
				)}
			/>
		)}
	</UploadFieldInner>
))

export type AudioFieldProps = BaseUploadFieldProps & AudioFileTypeProps

export const AudioField = Component<AudioFieldProps>(props => (
	<UploadFieldInner {...props} fileType={createAudioFileType(props)}>
		{props.children ?? <UploadedAudioView {...props} DestroyAction={DisconnectEntityTrigger} />}
	</UploadFieldInner>
))

export type VideoFieldProps = BaseUploadFieldProps & VideoFileTypeProps

export const VideoField = Component<VideoFieldProps>(props => (
	<UploadFieldInner {...props} fileType={createVideoFileType(props)}>
		{props.children ?? <UploadedVideoView {...props} DestroyAction={DisconnectEntityTrigger} />}
	</UploadFieldInner>
))

export type FileFieldProps = BaseUploadFieldProps & AnyFileTypeProps

export const FileField = Component<FileFieldProps>(props => (
	<UploadFieldInner {...props} fileType={createAnyFileType(props)}>
		{props.children ?? <UploadedAnyView {...props} DestroyAction={DisconnectEntityTrigger} />}
	</UploadFieldInner>
))

type UploadFieldInnerProps = BaseUploadFieldProps &
	FileUrlDataExtractorProps & {
		fileType: FileType
		children: ReactNode
	}

const UploadFieldInner = Component(
	({ baseField, label, description, children, fileType, urlField, dropzonePlaceholder }: UploadFieldInnerProps) => {
		const entity = useEntity()
		const defaultUploader = useS3Client()
		const [fileTypeStable] = useState(fileType)
		const fileTypeWithUploader = useMemo(
			() => ({ ...fileTypeStable, uploader: fileTypeStable?.uploader ?? defaultUploader }),
			[defaultUploader, fileTypeStable],
		)

		// const errors = type.extractors?.flatMap(it => it.getErrorsHolders?.({
		// 	environment,
		// 	entity,
		// }) ?? []).flatMap(it => it.errors?.errors ?? [])

		const id = useId()
		return (
			<FormFieldIdContext.Provider value={id}>
				<FormErrorContext.Provider value={[]}>
					<FormContainer description={description} label={label}>
						<div className="flex">
							<Uploader baseField={baseField} fileType={fileTypeWithUploader}>
								<UploaderBase baseField={baseField}>
									<UploaderHasFile>
										<UploaderProgress />
									</UploaderHasFile>
								</UploaderBase>

								<UploaderItemUI>
									<EntityView
										render={entity => {
											entity = baseField ? entity.getEntity({ field: baseField }) : entity
											if (entity.getField(urlField).value === null) {
												return <UploaderDropzone inactiveOnUpload dropzonePlaceholder={dropzonePlaceholder} />
											}
											return children

										}}
									/>
								</UploaderItemUI>
							</Uploader>
						</div>
					</FormContainer>
				</FormErrorContext.Provider>
			</FormFieldIdContext.Provider>
		)
	},
	({ fileType, children, baseField }) => {
		return (
			<>
				{children}
				<Uploader baseField={baseField} fileType={fileType}>
					<Field field="alt" />
				</Uploader>
			</>
		)
	},
)
