import { Binding, PersistButton } from '@app/lib/binding'
import { CheckboxField, InputField } from '@app/lib/form'
import { Dialog, DialogContent, DialogTrigger } from '@app/lib/ui/dialog'
import { RadioInput } from '@app/lib/ui/input'
import {
	Component,
	EntitySubTree,
	EnvironmentMiddleware,
	Field,
	SugaredRelativeSingleField,
	useEntity,
	useField,
} from '@contember/interface'
import * as React from 'react'
import { CSSProperties, Fragment, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import './focal-point.css'

type PointConfig = {
	xField: SugaredRelativeSingleField['field']
	yField: SugaredRelativeSingleField['field']
	label?: string
	color?: CSSProperties['background']
	size?: CSSProperties['width']
}

type PreviewConfig = {
	width: number
	height: number
	label?: string
	pointPriority?: number[]
}

interface FocalPointEditorProps extends PropsWithChildren {
	urlField: SugaredRelativeSingleField['field']
	widthField: SugaredRelativeSingleField['field']
	heightField: SugaredRelativeSingleField['field']
	points: PointConfig[]
	previews?: PreviewConfig[]
}

const colors = ['red', 'green', 'blue', 'orange']
const FocalPointMarker = ({
	point,
	index,
}: {
	point: PointConfig
	index: number
}) => {
	const xField = useField<number>(point.xField).value
	const yField = useField<number>(point.yField).value
	const color = point.color ?? colors[index % colors.length]

	if (xField === null || yField === null) {
		return null
	}

	return (
		<div
			className="cui-focal-point-marker"
			style={{
				background: color,
				left: `${xField * 100}%`,
				top: `${yField * 100}%`,
			}}
		/>
	)
}

export const focalPointPreviews = [
	{
		label: 'Náhled článku',
		height: 232,
		width: 405,
		pointPriority: [1, 0],
	},
	{
		label: 'Podcast',
		height: 405,
		width: 405,
		pointPriority: [1, 0],
	},
]

export const focalPointPoints = [
	{
		xField: 'focalPointX',
		yField: 'focalPointY',
		label: 'Primary',
	},
]

const ImagePreview = ({
	url,
	focalPoint,
	preview,
}: {
	url: string
	focalPoint?: [number, number]
	preview: PreviewConfig
}) => {
	return (
		<div>
			<h2 className="font-lg">{preview.label}</h2>
			<div className={'cui-focal-point-photo-holder'}>
				<div
					style={{
						backgroundImage: `url("${url}")`,
						width: `${preview.width}px`,
						height: `${preview.height}px`,
						backgroundSize: 'cover',
						backgroundPosition: focalPoint ? `${(focalPoint?.[0] ?? 0) * 100}% ${(focalPoint?.[1] ?? 0) * 100}%` : '50% 50%',
					}}
				/>
			</div>
		</div>
	)
}

export const FocalPointEditor = Component<FocalPointEditorProps>(
	({ points, urlField, widthField, heightField, previews }) => {
		const entity = useEntity()
		const [activePoint, setActivePoint] = useState(0)
		const urlFieldAccessor = useField<string>(urlField)
		const widthFieldAccessor = useField<number>(widthField)
		const heightFieldAccessor = useField<number>(heightField)
		const url = urlFieldAccessor.value
		const width = widthFieldAccessor.value
		const height = heightFieldAccessor.value

		const handleClick = useCallback(
			(e: React.MouseEvent<HTMLImageElement>) => {
				const point = points[activePoint]
				const x = e.nativeEvent.offsetX / e.currentTarget.offsetWidth
				const y = e.nativeEvent.offsetY / e.currentTarget.offsetHeight
				entity.getField(point.xField).updateValue(x)
				entity.getField(point.yField).updateValue(y)
			},
			[activePoint, entity, points],
		)

		const resolvedFocalPoints = useMemo(
			() =>
				points.map((it): [number, number] | undefined => {
					const xField = entity.getField<number>(it.xField).value
					const yField = entity.getField<number>(it.yField).value
					return xField !== null && yField !== null ? [xField, yField] : undefined
				}),
			[entity, points],
		)

		const radioOptions = points.map((it, index) => ({
			label: it.label ?? `Point #${index + 1}`,
			value: String(index),
		}))

		if (!url) {
			return null
		}

		return (
			<>
				<div className={'flex flex-col gap-4 cui-focal-point-editor-body'}>
					<div className={'cui-focal-point-editor-photo cui-focal-point-photo-holder'}>
						<div className={'cui-focal-point-editor-photo-img-wrapper'}>
							<img src={url} onClick={handleClick} width={width ?? undefined} height={height ?? undefined} alt={'image'} />
							{points.map((it, index) => (
								<FocalPointMarker point={it} index={index} key={index} />
							))}
						</div>
					</div>
					{points.length > 1 &&
						radioOptions.map(it => {
							return (
								<div key={it.label} className={'flex gap-2 items-center'}>
									<RadioInput
										id={it.label}
										key={it.value}
										name={'activePoint'}
										value={String(it.value)}
										checked={activePoint === Number.parseInt(it.value, 10)}
										onChange={event => setActivePoint(Number.parseInt(event.target.value, 10))}
									/>
									<label htmlFor={it.label}>{it.label}</label>
								</div>
							)
						})}
				</div>
				<div className={'flex gap-4 flex-wrap lg:flex-col'}>
					{previews?.map((it, index) => {
						let resolvedPoint: [number, number] | undefined = undefined
						for (const point of it.pointPriority ?? [0]) {
							resolvedPoint = resolvedFocalPoints[point] ?? undefined
							if (resolvedPoint !== undefined) {
								break
							}
						}
						return <ImagePreview key={index} url={url} preview={it} focalPoint={resolvedPoint} />
					})}
				</div>
			</>
		)
	},
	props => {
		return (
			<>
				<Field field={props.urlField} />
				<Field field={props.heightField} />
				<Field field={props.widthField} />
				{props.points.map((it, i) => (
					<Fragment key={i}>
						<Field field={it.xField} />
						<Field field={it.yField} />
					</Fragment>
				))}
			</>
		)
	},
)

export const MediaEditModal = Component<FocalPointEditorProps>(props => {
	return (
		<>
			<div className={'flex flex-col lg:flex-row gap-4'}>
				<FocalPointEditor {...props} />
				<div className={'flex flex-col gap-4 w-full lg:w-1/3'}>
					<div className={'grid grid-cols-12 gap-4'}>
						<div className={'col-span-12'}>
							<InputField field="alt" label="Alternativní popisek" />
						</div>
					</div>
					<InputField field="meta.fileName" label="Název souboru" />
					<div className={'grid grid-cols-12 gap-4'}>
						<div className={'col-span-6'}>
							<InputField inputProps={{ readOnly: true }} field="createdAt" label="Vytvořeno" />
						</div>
						<div className={'col-span-6'}>
							<InputField inputProps={{ readOnly: true }} field="meta.fileType" label="Typ souboru" />
						</div>
						<div className="col-span-12">
							<CheckboxField
								field="isFromPhotoBank"
								label="Z fotobanky"
								description="Obrázky z fotobanky nejsou dostupné v media gallery pro vložení do článku."
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
})

export const FocalPointDialogOpener = Component<
	FocalPointEditorProps & {
		entity: string
	}
>(
	({ entity, children, ...rest }) => {
		const entityId = useEntity().idOnServer

		if (!entityId) {
			return children
		}

		return (
			<Dialog>
				<DialogTrigger asChild>
					<div className={'contents'}>{children}</div>
				</DialogTrigger>
				<DialogContent className={'max-w-[90vw] max-h-[90vh] overflow-auto'}>
					<Binding>
						<EnvironmentMiddleware create={env => env.withVariables({ id: entityId })}>
							<EntitySubTree entity={entity}>
								<MediaEditModal {...rest} />
							</EntitySubTree>
						</EnvironmentMiddleware>
						<div className="flex gap-4 justify-end">
							<PersistButton />
						</div>
					</Binding>
				</DialogContent>
			</Dialog>
		)
	},
	({ children }) => children,
)
