import { TextFilterArtifactsMatchMode } from '@contember/react-dataview'
import { InviteErrorCodes } from './tenant/hooks/useInvite'
import { UploaderErrorType } from '@contember/react-uploader'
import { ChangeMyPasswordFormErrorCode, CreateApiKeyFormErrorCode } from '@contember/interface'
import { InviteFormErrorCode, LoginFormErrorCode, OtpPrepareFormErrorCode, OtpConfirmFormErrorCode, PasswordResetFormErrorCode, PasswordResetRequestFormErrorCode, UpdateProjectMemberFormErrorCode, PasswordlessSignInInitFormErrorCode, PasswordlessSignInFormErrorCode } from '@contember/react-identity'
import { ActivatePasswordlessOtpErrorCode } from '@contember/graphql-client-tenant'

export const dict = {
	deleteEntityDialog: {
		title: 'Are you absolutely sure?',
		description: 'This action cannot be undone.',
		confirmButton: 'Delete',
		cancelButton: 'Cancel',
	},
	identityLoader: {
		fail: 'Failed to load identity',
	},
	persist: {
		persistButton: 'Save data',
		invalidInputError: 'Failed to save data',
		invalidResponseError: 'Invalid response',
		invalidResponseErrorDetails: 'The server returned an invalid response. Please try again later.',
		success: 'Successfully saved',
		afterPersistError: 'Something wrong has happened after the data were persisted. Please refresh the page.',
	},
	toast: {
		showDetails: 'Show details',
	},
	input: {
		noValue: 'No value',
	},
	datagrid: {
		na: 'N/A',
		dateStart: 'From',
		today: 'Today',
		dateEnd: 'To',
		numberFrom: 'From',
		numberTo: 'To',
		textReset: 'Reset filter',
		textPlaceholder: 'Search',
		textMatchMode: {
			'matches': 'contains',
			'matchesExactly': 'equals',
			'startsWith': 'starts with',
			'endsWith': 'ends with',
			'doesNotMatch': 'does not contain',
		} satisfies Record<TextFilterArtifactsMatchMode, string>,
		visibleFields: 'Fields',
		columnAsc: 'asc',
		columnDesc: 'desc',
		columnHide: 'Hide',
		empty: 'No results.',
		layout: 'Layout',
		showGrid: 'Grid',
		showTable: 'Table',
		paginationFirstPage: 'First page',
		paginationPreviousPage: 'Previous page',
		paginationNextPage: 'Next page',
		paginationLastPage: 'Last page',
		paginationRowsPerPage: 'Rows per page',

		pageInfo: 'Page ${page} of ${pagesCount}',
		pageInfoShort: 'Page ${page}',
		pageRowsCount: '${totalCount} rows total',

		filter: 'Filter',
		filters: 'Filters',
		exclude: 'Exclude',
		export: 'Export',

	},
	select: {
		confirmNew: 'Confirm',
		cancelNew: 'Cancel',
		search: 'Search',
		paginationPreviousPage: 'Previous page',
		paginationNextPage: 'Next page',
		placeholder: 'Select',
	},
	backButton: {
		back: 'Back',
	},

	errors: {
		required: 'This field is required',
		unique: 'This value is already taken',
		unknown: 'An unknown error has occurred',
	},

	boolean: {
		true: 'Yes',
		false: 'No',
	},
	logout: 'Log out',
	inviteErrors: {
		ALREADY_MEMBER: 'This user is already a member of the project',
		INVALID_EMAIL_FORMAT: 'Invalid email format',
		INVALID_MEMBERSHIP: 'Invalid membership',
		PROJECT_NOT_FOUND: 'Project not found',
		ROLE_NOT_FOUND: 'Role not found',
		VARIABLE_EMPTY: 'Variable is empty',
		VARIABLE_NOT_FOUND: 'Variable not found',
		fallback: 'Failed to invite user',
	} satisfies Record<InviteErrorCodes | 'fallback',  string>,
	uploader: {
		uploadErrors: {
			httpError: 'HTTP error',
			aborted: 'Upload aborted',
			networkError: 'Network error',
			timeout: 'Upload timeout',
			fileRejected: 'File rejected',
		} satisfies Record<UploaderErrorType, string>,
		uploaderUnknownError: 'Unknown error',

		browseFiles: 'Browse',
		dropFiles: 'Drop files here',
		or: 'or',
	},
	repeater: {
		empty: 'No items.',
		addItem: 'Add item',
	},
	outdatedApplication: {
		title: 'An updated version is available',
		description: 'To access the latest features and improvements, kindly refresh your browser.',
		warning: 'Any unsaved data will be lost. Please save your work before refreshing.',
		snooze: 'Snooze',
		refreshNow: 'Refresh now',
	},
	tenant: {
		memberList: {
			failedToLoadData: 'Failed to load data',
			noResults: 'No results',
			previous: 'Previous',
			next: 'Next',
			roles: 'Roles',
			action: 'Action',
		},
		apiKeyList: {
			deleted: 'API key deleted',
			deleteFailed: 'Failed to delete API key',
			unnamed: 'Unnamed API key',
			noResults: 'No API keys',
			description: 'Description',
			role: 'Role',
			action: 'Action',
			deleteConfirmation: 'Are you sure you want to delete this API key?',
			deleteCancelAction: 'Cancel',
			deleteConfirmAction: 'Delete',
		},
		createApiKey: {
			description: 'Description',
			roles: 'Roles',
			submit: 'Create API key',
			errorMessages: {
				'FIELD_REQUIRED': 'This field is required',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
				'INVALID_MEMBERSHIP': 'Invalid membership',
				'PROJECT_NOT_FOUND': 'Project not found',
				'ROLE_NOT_FOUND': 'Role not found',
				'VARIABLE_EMPTY': 'Variable empty',
				'VARIABLE_NOT_FOUND': 'Variable not found',
			} satisfies Record<CreateApiKeyFormErrorCode, string>,
		},
		invite: {
			email: 'Email',
			name: 'Name',
			roles: 'Roles',
			addRole: 'Add new role',
			submit: 'Invite',
			errorMessages: {
				'FIELD_REQUIRED': 'This field is required',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
				'ALREADY_MEMBER': 'This person is already a member',
				'INVALID_EMAIL_FORMAT': 'Invalid email format',
				'INVALID_MEMBERSHIP': 'Invalid membership',
				'PROJECT_NOT_FOUND': 'Project not found',
				'ROLE_NOT_FOUND': 'Role not found',
				'VARIABLE_EMPTY': 'Variable empty',
				'VARIABLE_NOT_FOUND': 'Variable not found',
			} satisfies Record<InviteFormErrorCode, string>,
		},
		changePassword: {
			currentPassword: 'Current password',
			newPassword: 'New password',
			confirmPassword: 'Confirm password',
			submit: 'Change password',
			errorMessages: {
				'FIELD_REQUIRED': 'This field is required',
				'INVALID_VALUE': 'Invalid value',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
				'PASSWORD_MISMATCH': 'Passwords do not match',
				'TOO_WEAK': 'Password is too weak',
				'NO_PASSWORD_SET': 'Your account has no password set',
				'INVALID_PASSWORD': 'Invalid password',
				'NOT_A_PERSON': 'Invalid user',
			} satisfies Record<ChangeMyPasswordFormErrorCode, string>,
		},
		login: {
			email: 'Email',
			password: 'Password',
			otpToken: '2FA OTP token',
			forgotPassword: 'Forgot password?',
			login: 'Login',
			errorMessages: {
				'FIELD_REQUIRED': 'This field is required',
				'INVALID_VALUE': 'Invalid value',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
				'INVALID_PASSWORD': 'Invalid password',
				'INVALID_OTP_TOKEN': 'Invalid OTP token',
				'NO_PASSWORD_SET': 'No password set',
				'PERSON_DISABLED': 'Person disabled',
				'UNKNOWN_EMAIL': 'Unknown email',
				'OTP_REQUIRED': undefined,
			} satisfies Record<LoginFormErrorCode, string | undefined>,
			idpInitError: 'Failed to initialize IdP login:',
			idpResponseError: 'Failed to process IdP response:',
			title: 'Login',
			description: 'Enter your email below to login to your account',
			backToLogin: 'Back to login',
		},
		personList: {
			email: 'Email',
			name: 'Name',
			deleted: 'Member deleted',
			deleteFailed: 'Failed to delete member',
			noEmail: 'User has no email',
			noName: 'User has no name',
			role: 'Role',
			action: 'Action',
			noResults: 'No members',
			deleteConfirmation: 'Are you sure you want to delete this member?',
			deleteCancelAction: 'Cancel',
			deleteConfirmAction: 'Delete',
		},
		memberDelete: {
			cancel: 'Cancel',
			confirm: 'Confirm',
		},
		otpSetup: {
			prepareContinue: 'Continue',
			otpConfirmFormErrorMessages: {
				UNKNOWN_ERROR: 'Something went wrong. Please try again later',
				INVALID_OTP_TOKEN: 'Invalid OTP token',
				FIELD_REQUIRED: 'This field is required',
				NOT_PREPARED: 'OTP setup was not initialized',
			} satisfies Record<OtpConfirmFormErrorCode, string>,
			otpPrepareFormErrorMessages: {
				UNKNOWN_ERROR: 'Something went wrong. Please try again later',
			} satisfies Record<OtpPrepareFormErrorCode, string>,
			alreadyHaveOtp: 'You already have two-factor authentication active. By clicking "Continue", the old one will no longer work.',
			nowScanQrCode: 'Now scan the following QR code in your application:',
			writeDownCode: 'After registering to your application, write down a code from the application.',
			twoFactorEnabled: 'Two factor authentication is ENABLED.',
			disableTwoFactor: 'Disable two-factor authentication',
			disableTwoFactorConfirm: 'Are you sure you want to disable two-factor authentication?',
			cancel: 'Cancel',
			disable: 'Disable',
			disabledSuccess: 'Two-factor authentication was disabled.',
			setupAgain: 'Setup again',
			description: '2-Step Verification provides stronger security for your account by requiring a second step of verification when you sign in. In addition to your password, you’ll also need a code generated by an application on your phone.',
			setupTwoFactor: 'Setup two-factor authentication',
			enabledSuccess: 'Two-factor authentication was enabled.',
			otpToken: 'OTP token',
			label: 'Label',
			confirm: 'Confirm',
		},

		passwordReset: {
			token: 'Reset code',
			password: 'Password',
			passwordConfirmation: 'Confirm password',
			submit: 'Reset password',
			errorMessages: {
				'FIELD_REQUIRED': 'This field is required',
				'INVALID_VALUE': 'Invalid value',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
				'PASSWORD_MISMATCH': 'Passwords do not match',
				'PASSWORD_TOO_WEAK': 'Password is too weak',
				'TOKEN_EXPIRED': 'Token expired',
				'TOKEN_NOT_FOUND': 'Token not found',
				'TOKEN_USED': 'Token already used',
				'TOKEN_INVALID': 'Invalid token',
			} satisfies Record<PasswordResetFormErrorCode, string>,
			title: 'Reset password',
			description: 'Enter new password below',
			success: 'Password has been reset',
		},
		passwordResetRequest: {
			email: 'E-mail',
			submit: 'Request reset code',
			errorMessages: {
				'FIELD_REQUIRED': 'This field is required',
				'INVALID_VALUE': 'Invalid value',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
				'PERSON_NOT_FOUND': 'Unknown email',
			} satisfies Record<PasswordResetRequestFormErrorCode, string>,
			title: 'Reset password',
			description: 'Enter your email below to reset your password',
			success: 'Password reset link has been sent',
			checkMail: 'Please check your mailbox for instructions on how to reset your password.',
			entryCode: 'Or enter the reset code directly.',
		},
		passwordlessSignInInit: {
			email: 'E-mail',
			submit: 'Send magic link',
			errorMessages: {
				'FIELD_REQUIRED': 'This field is required',
				'INVALID_VALUE': 'Invalid value',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
				'PERSON_NOT_FOUND': 'Unknown email',
				'PASSWORDLESS_DISABLED': 'Passwordless sign-in is not available',
			} satisfies Record<PasswordlessSignInInitFormErrorCode, string>,
			title: 'Sign in',
			description: 'Enter your email below to sign in to your account',
			success: 'Magic link has been sent',
			checkMail: 'Please check your mailbox for instructions on how to sign in.',
			entryCode: 'Or enter the reset code directly.',
		},
		passwordlessSignIn: {
			token: 'Verification code',
			otpToken: '2FA OTP token',
			submit: 'Sign in',
			errorMessages: {
				'FIELD_REQUIRED': 'This field is required',
				'INVALID_VALUE': 'Invalid value',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
				'INVALID_OTP_TOKEN': 'Invalid OTP token',
				'OTP_REQUIRED': undefined,
				'PERSON_DISABLED': 'Person disabled',
				'TOKEN_EXPIRED': 'Token expired',
				'TOKEN_NOT_FOUND': 'Token not found',
				'TOKEN_USED': 'Token already used',
				'TOKEN_INVALID': 'Invalid token',
			} satisfies Record<PasswordlessSignInFormErrorCode, string | undefined>,
			title: 'Sign in',
		},
		otpActivation: {
			errorMessages: {
				'TOKEN_INVALID': 'This token is not valid. Please try again.',
				'TOKEN_USED': 'Token already used',
				'TOKEN_NOT_FOUND': 'Token not found',
				'TOKEN_EXPIRED': 'Token expired',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
			} satisfies Record<ActivatePasswordlessOtpErrorCode | 'UNKNOWN_ERROR', string>,
		},

		updateProjectMember: {
			updateSuccess: 'Roles updated',
			roles: 'Roles',
			submit: 'Update role',
			errorMessages: {
				'FIELD_REQUIRED': 'This field is required',
				'UNKNOWN_ERROR': 'Something went wrong. Please try again later',
				'INVALID_MEMBERSHIP': 'Invalid membership',
				'PROJECT_NOT_FOUND': 'Project not found',
				'ROLE_NOT_FOUND': 'Role not found',
				'VARIABLE_EMPTY': 'Variable empty',
				'VARIABLE_NOT_FOUND': 'Variable not found',
				'NOT_MEMBER': 'Not a member',
			} satisfies Record<UpdateProjectMemberFormErrorCode, string>,
		},
	},
}
export const dictFormat = (value: string, replacements: Record<string, string>) => {
	return value.replace(/\${([^}]+)}/g, (_, key) => replacements[key] || '')
}
